import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {DateInput} from "semantic-ui-calendar-react";
import {Button, Input, Header, Confirm, Icon, Popup, Dropdown, Grid} from "semantic-ui-react";
import {deleteMilestone, updateMilestone} from "../actions/milestoneActions";
import * as moment from "moment";
import Goal from "./Goal";
import {createGoal, deleteGoal, loadGoals} from "../actions/goalActions";
import {getGoalCalculatedValues, goalsComparer} from "./utils";
import GoalsPieChart from "./GoalsPieChart";
import GoalsProgressChart from "./GoalsProgressChart";
import PromptModal from "./PromptModal";
import PropTypes from 'prop-types';

class Milestone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            goalToDelete: undefined,
            selectedCategory: 'all',
            showDeleteGoalConfirmation: false,
            showDeleteMilestoneConfirmation: false,
            name: props.milestone.name,
            startDate: moment(props.milestone.startDate, 'YYYY-MM-DD').format('MM/DD/YYYY'),
            endDate: moment(props.milestone.endDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
        };
    }

    componentDidMount() {
        this.props.loadGoals(this.props.milestone.milestoneId);
    }

    onNameChange = (event, {value}) => {
        this.setState({name: value});
    };

    onStartDateChange = (event, {value}) => {
        this.setState({startDate: value});
    };

    onEndDateChange = (event, {value}) => {
        this.setState({endDate: value});
    };

    canUpdate = () => {
        return this.dataChanged() &&
            !this.props.milestone.completed &&
            this.state.name &&
            moment(this.state.startDate).isBefore(moment(this.state.endDate));
    };

    update = () => {
        this.props.updateMilestone({
            milestoneId: this.props.milestone.milestoneId,
            completed: this.props.milestone.completed || false,
            name: this.state.name,
            startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.state.endDate).format('YYYY-MM-DD')
        });
    };

    canComplete = () => {
        return !this.props.milestone.completed;
    };

    complete = () => {
        this.props.updateMilestone({
            milestoneId: this.props.milestone.milestoneId,
            completed: true,
            name: this.state.name,
            startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.state.endDate).format('YYYY-MM-DD')
        })
    };

    canDelete = () => {
        return true;
    };

    tryDeleteMilestone = () => {
        this.setState({showDeleteMilestoneConfirmation: true});
    };

    cancelMilestoneDeletion = () => {
        this.setState({showDeleteMilestoneConfirmation: false});
    };

    delete = () => {
        this.props.deleteMilestone({
            milestoneId: this.props.milestone.milestoneId,
            completed: true,
            name: this.state.name,
            startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.state.endDate).format('YYYY-MM-DD')
        });
        this.setState({showDeleteMilestoneConfirmation: false});
    };

    tryDeleteGoal = (goal) => {
        this.setState({showDeleteGoalConfirmation: true, goalToDelete: goal});
    };

    cancelGoalDeletion = () => {
        this.setState({showDeleteGoalConfirmation: false});
    };

    deleteGoal = () => {
        this.props.deleteGoal(this.state.goalToDelete);
        this.setState({showDeleteGoalConfirmation: false});
    };

    onCategoryChange = (e, {value}) => {
        this.setState({selectedCategory: value});
    };

    dataChanged = () => {
        return this.state.name !== this.props.milestone.name ||
            moment(this.state.startDate).format('YYYY-MM-DD') !== this.props.milestone.startDate ||
            moment(this.state.endDate).format('YYYY-MM-DD') !== this.props.milestone.endDate;
    };

    render() {
        const goals = (this.props.goals || [])
            .filter(g => this.state.selectedCategory === 'all' || g.categoryId === this.state.selectedCategory)
            .map(g => getGoalCalculatedValues(g, this.props.milestone))
            .sort(goalsComparer);

        const categoryOptions = [
            {
                value: 'all',
                text: 'All'
            },
            ...((this.props.categories || []).map(c => {
                return {
                    value: c.categoryId,
                    text: c.name
                }
            }))
        ];

        return (
            <>
                <PromptModal when={this.dataChanged()} message='Milestone was not saved. Continue and lost changes?'/>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', marginBottom: '5px'}}>
                    <div style={{margin: '5px 5px 5px 10px', width: '40px', minWidth: '40px'}}>Name:</div>
                    <Input
                        value={this.state.name}
                        style={{width: '150px', flexGrow: 1}}
                        onChange={this.onNameChange}
                        disabled={this.props.milestone.completed}
                    />
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center'}}>
                    <div style={{margin: '5px 5px 5px 10px', width: '40px', minWidth: '40px'}}>Start:</div>
                    <DateInput
                        style={{width: '130px'}}
                        dateFormat='MM/DD/YYYY'
                        iconPosition='left'
                        value={this.state.startDate}
                        onChange={this.onStartDateChange}
                        disabled={this.props.milestone.completed}
                    />
                    <div style={{margin: '5px 5px 5px 10px', width: '30px', minWidth: '30px'}}>End:</div>
                    <DateInput
                        style={{width: '130px'}}
                        dateFormat='MM/DD/YYYY'
                        iconPosition='left'
                        value={this.state.endDate}
                        onChange={this.onEndDateChange}
                        disabled={this.props.milestone.completed}
                    />
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
                    <Popup
                        trigger={
                            <Button
                                icon
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '5px',
                                    width: '70px',
                                    justifyContent: 'space-around'
                                }}
                                onClick={this.update}
                                disabled={!this.canUpdate()}
                                positive
                            >
                                <Icon name='save'/>
                                <div style={{margin: '0 5px'}}>Save</div>
                            </Button>
                        }
                        content="Save Milestone"
                        basic
                    />
                    <Popup
                        trigger={
                            <Button
                                icon
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '5px',
                                    width: '100px',
                                    justifyContent: 'space-around'
                                }}
                                onClick={this.complete}
                                disabled={!this.canComplete()}
                                color='orange'
                            >
                                <Icon name='stop'/>
                                <div style={{margin: '0 5px'}}>Complete</div>
                            </Button>
                        }
                        content="Complete Milestone (after completion Milestone is read only and visible trough menu 'Show Completed Milestones')"
                        basic
                    />
                    <Popup
                        trigger={
                            <Button
                                icon
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '5px',
                                    width: '90px',
                                    justifyContent: 'space-around'
                                }}
                                onClick={this.tryDeleteMilestone}
                                disabled={!this.canDelete()}
                                negative
                            >
                                <Icon name='delete'/>
                                <div style={{margin: '0 5px'}}>Delete</div>
                            </Button>
                        }
                        content="Delete Milestone"
                        basic
                    />
                    <Popup
                        trigger={
                            <Button
                                icon
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    margin: '5px',
                                    width: '110px',
                                    minWidth: '110px',
                                    justifyContent: 'space-around'
                                }}
                                as={Link}
                                to={`goals/newGoal/${this.props.milestone.milestoneId}`}
                                disabled={!this.canDelete()}
                                positive
                            >
                                <Icon name='plus'/>
                                <div style={{margin: '0 5px'}}>New Goal</div>
                            </Button>
                        }
                        content="New Goal"
                        basic
                    />
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center'}}>
                    <div style={{margin: '5px', width: '70px'}}>Category:</div>
                    <Dropdown
                        fluid
                        selection
                        value={this.state.selectedCategory}
                        options={categoryOptions}
                        style={{width: '150px'}}
                        onChange={this.onCategoryChange}
                    />
                </div>
                <Grid>
                    <Grid.Column tablet={16} largeScreen={9} widescreen={9}>
                        <GoalsProgressChart milestone={this.props.milestone} goals={goals}/>
                    </Grid.Column>
                    <Grid.Column tablet={16} largeScreen={7} widescreen={7}>
                        <GoalsPieChart goals={goals}/>
                    </Grid.Column>
                </Grid>
                {this.props.milestone.goals && this.props.milestone.goals.length > 0 ?
                    <Header as='h4' style={{margin: '5px'}}>Goals</Header> : undefined}
                {goals.map(g =>
                    <Goal
                        key={g.goalId}
                        goal={g}
                        milestone={this.props.milestone}
                        deleteGoal={this.tryDeleteGoal}
                    />
                )}
                <Confirm
                    open={this.state.showDeleteGoalConfirmation}
                    header='Confirmation'
                    content='Delete Goal?'
                    onCancel={this.cancelGoalDeletion}
                    onConfirm={this.deleteGoal}
                />
                <Confirm
                    open={this.state.showDeleteMilestoneConfirmation}
                    header='Confirmation'
                    content='Delete Milestone?'
                    onCancel={this.cancelMilestoneDeletion}
                    onConfirm={this.delete}
                />
            </>
        );
    }
}

Milestone.propTypes = {
    milestone: PropTypes.object.isRequired
};

Milestone.defaultProps = {};

const mapStateToProps = state => ({
    categories: state.data.categories,
    goals: state.data.goals
});

const mapDispatchToProps = {
    updateMilestone,
    deleteMilestone,
    loadGoals,
    createGoal,
    deleteGoal,

};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Milestone);