import * as moment from "moment";

export const getGoalCalculatedValues = (goal, milestone) => {
    let value = 1.0 * goal.startValue;
    if (goal.trackings) {
        let lastDate;

        for (const tracking of goal.trackings) {
            if (!lastDate || moment(tracking.date).isAfter(moment(lastDate))) {
                lastDate = tracking.date;
                value = 1.0 * tracking.value;
            }
        }
    }

    const startValue = 1.0 * goal.startValue;
    const endValue = 1.0 * goal.endValue;
    const direction = startValue < endValue ? 1 : -1;
    const daysInMilestone = getMilestoneDays(milestone);
    const daysBeforeStart = moment().diff(moment(milestone.startDate), 'days');
    const expectedCompletion = 100 * daysBeforeStart / daysInMilestone;
    const actualCompletion = 100 * (Math.abs(value + direction * 0.5 - startValue)) / (Math.abs(endValue - startValue));
    let status;

    if (actualCompletion >= 100) {
        status = 2;
    } else {
        if (goal.trackingType === 'YES_NO') {
            if (expectedCompletion < 50) {
                status = 1;
            } else if (expectedCompletion < 90) {
                status = 0;
            } else {
                status = -1;
            }
        } else {
            if (actualCompletion >= expectedCompletion - 5) {
                status = 1;
            } else if (actualCompletion >= expectedCompletion - 15) {
                status = 0;
            } else {
                status = -1;
            }
        }
    }

    let trackings = (goal.trackings || []).map(t => {
        return {
            ...t,
            days: moment(milestone.startDate).diff(moment(t.date), 'days')
        }
    });
    trackings = trackings
        .filter(t => !trackings.find(t1 => t1.days === t.days && t1.date > t.date))
        .sort((t1, t2) => t1.date - t2.date);

    return {
        ...goal,
        value,
        expectedCompletion,
        actualCompletion,
        status,
        trackings
    };
};

export const goalsComparer = (goal1, goal2) => {
    if (goal1.priority > goal2.priority) {
        return -1;
    }

    if (goal1.priority < goal2.priority) {
        return 1;
    }

    return goal1.name.localeCompare(goal2.name);
};

export const getMilestoneDays = (milestone) => {
    return moment(milestone.endDate).diff(moment(milestone.startDate), 'days');
};

export const getUID = () => {
    return Math.random().toString(36).substring(2) + Date.now().toString(36);
};