import * as types from './actionTypes';
import {toastr} from "react-redux-toastr";
import history from "../history";
import firebase from '../firebaseApp';
import {getUID} from "../components/utils";
import {beginAjaxCall, endAjaxCall} from "./ajaxStatusActions";

export function loadMilestones(includeCompleted) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        firebase.firestore()
            .collection('milestones')
            .where("userId", "==", firebase.auth().currentUser.uid)
            .get()
            .then((querySnapshot) => {
                let milestones = querySnapshot.docs.map(doc => doc.data())

                if (milestones) {
                    if (milestones.filter(m => !m.completed).length === 0) {
                        toastr.info('You don\'t have any active milestones. It\'s time to create one', {
                            id: 'no-milestones'
                        })
                    } else {
                        toastr.remove('no-milestones');
                    }

                    dispatch({type: types.LOAD_MILESTONES_SUCCESS, data: milestones.filter(m => includeCompleted || !m.completed), includeCompleted});
                }

            })
            .catch(error => toastr.error('Error loading milestones'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function updateMilestone(milestone) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        firebase.firestore()
            .collection('milestones')
            .doc(milestone.milestoneId)
            .update(milestone)
            .then(() => {
                dispatch(loadMilestones(getState().data.includeCompleted));
            })
            .catch(error => toastr.error('Error updating milestone'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function createMilestone(milestone, onSuccess) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        let milestoneId = getUID();

        firebase.firestore()
            .collection('milestones')
            .doc(milestoneId)
            .set({...milestone, userId: firebase.auth().currentUser.uid, milestoneId, completed: false})
            .then(() => {
                dispatch(loadMilestones(getState().data.includeCompleted));
                if (onSuccess) {
                    onSuccess();
                }

                history.push('/' + milestoneId);
            })
            .catch(error => toastr.error('Error adding milestone'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function deleteMilestone(milestone) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        firebase.firestore()
            .collection('milestones')
            .doc(milestone.milestoneId)
            .delete()
            .then(() => {
                dispatch(loadMilestones(getState().data.includeCompleted));
                history.push('/');
            })
            .catch(error => toastr.error('Error deleting milestone'))
            .finally(() => dispatch(endAjaxCall()));
    }
}
