import * as types from './actionTypes';
import {toastr} from "react-redux-toastr";
import firebase from "../firebaseApp";

export function track(tracking, goal) {
    return function (dispatch) {
        dispatch({type: types.TRACK, tracking});
        let updatedGoal = {...goal, trackings: ([...(goal.trackings || []), tracking])};

        console.log(updatedGoal);

        firebase.firestore()
            .collection('goals')
            .doc(goal.goalId)
            .update(updatedGoal)
            .catch(error => {
                toastr.error('Error updating goal');
                dispatch({type: types.TRACK_ERROR, tracking});
            });
    }
}
