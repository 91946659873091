import * as types from './actionTypes';
import {toastr} from "react-redux-toastr";
import history from '../history';
import firebase from "../firebaseApp";
import {getUID} from "../components/utils";
import {beginAjaxCall, endAjaxCall} from "./ajaxStatusActions";

export function loadCategories() {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        firebase.firestore()
            .collection('categories')
            .where("userId", "==", firebase.auth().currentUser.uid)
            .get()
            .then((querySnapshot) => {
                let categories = querySnapshot.docs.map(doc => doc.data())

                if (categories) {
                    if (categories.length === 0) {
                        // create categories

                        let categoriesToStart = ["Health", "Work", "Learning", "Hobby", "Finances"];
                        let categoriesToStartColors = ["green", "black", "yellow", "orange", "red"];
                        let promises = [];

                        for (let i = 0; i < 5; i++) {
                            let categoryId = getUID();
                            promises.push(firebase.firestore()
                                .collection('categories')
                                .doc(categoryId)
                                .set({
                                    categoryId,
                                    userId: firebase.auth().currentUser.uid,
                                    name: categoriesToStart[i],
                                    color: categoriesToStartColors[i]
                                }))
                        }

                        dispatch(beginAjaxCall());
                        Promise.all(promises)
                            .catch(error => toastr.error('Error generating categories' + error))
                            .then(() => dispatch({type: types.LOAD_CATEGORIES_SUCCESS, data: categories}))
                            .finally(() => dispatch(endAjaxCall()));
                    } else {
                        dispatch({type: types.LOAD_CATEGORIES_SUCCESS, data: categories});
                    }
                }

            })
            .catch(error => toastr.error('Error loading categories'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function updateCategory(category, onSuccess) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        firebase.firestore()
            .collection('categories')
            .doc(category.categoryId)
            .update(category)
            .then(() => {
                if (onSuccess) {
                    onSuccess();
                }

                dispatch(loadCategories());
                history.push('/categories');
            })
            .catch(error => toastr.error('Error updating category'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function createCategory(category, onSuccess) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        let categoryId = getUID();

        firebase.firestore()
            .collection('categories')
            .doc(categoryId)
            .set({...category, userId: firebase.auth().currentUser.uid, categoryId})
            .then(() => {
                dispatch(loadCategories());
                if (onSuccess) {
                    onSuccess();
                }

                history.push('/categories');
            })
            .catch(error => toastr.error('Error adding milestone'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function deleteCategory(category) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        firebase.firestore()
            .collection('categories')
            .doc(category.categoryId)
            .delete()
            .then(() => {
                dispatch(loadCategories());
            })
            .catch(error => toastr.error('Error deleting category'))
            .finally(() => dispatch(endAjaxCall()));
    }
}
