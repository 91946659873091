import React, {Component} from 'react';
import './App.css';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Router} from 'react-router-dom';
import Main from './components/Main'
import {Dimmer, Loader} from 'semantic-ui-react'
import history from './history'
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from './firebaseApp';

import 'react-redux-toastr/lib/css/react-redux-toastr.min.css'
import 'semantic-ui-css/semantic.min.css';
import About from "./components/About";


class App extends Component {
    state = {
        isSignedIn: false
    };

    uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // Redirect to / after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
        signInOptions: [
            firebase.auth.EmailAuthProvider.PROVIDER_ID,
            firebase.auth.GoogleAuthProvider.PROVIDER_ID
        ],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: () => false
        }
    };

    // Listen to the Firebase Auth state and set the local state.
    componentDidMount() {
        this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
            (user) => this.setState({isSignedIn: !!user})
        );
    }

    // Make sure we un-register Firebase observers when the component unmounts.
    componentWillUnmount() {
        this.unregisterAuthObserver();
    }

    render() {
        let content;

        if (this.state.isSignedIn) {
            content = (
                <Main userName={firebase.auth().currentUser.displayName}
                      pictureUrl={firebase.auth().currentUser.photoURL}
                      onLogout={() => firebase.auth().signOut()}
                      loading={this.props.loading}/>
            );
        } else {
            content = (
                this.props.loading ?
                    null :
                    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                        <StyledFirebaseAuth uiConfig={this.uiConfig} firebaseAuth={firebase.auth()}/>
                        <About/>
                    </div>
            );
        }

        return (
            <Router history={history}>
                <div>
                    {content}
                    <Dimmer active={this.props.loading}>
                        <Loader>Please wait...</Loader>
                    </Dimmer>
                </div>
            </Router>
        );
    }
}

App.propTypes = {
    auth: PropTypes.shape({
        isLogged: PropTypes.bool.isRequired,
        userName: PropTypes.string,
        pictureUrl: PropTypes.string
    }),
    loading: PropTypes.bool.isRequired
};

function mapStateToProps(state) {
    return {
        auth: state.auth,
        loading: state.ajaxCallsInProgress > 0
    };
}

export default connect(
    mapStateToProps
)(App);