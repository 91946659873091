import * as React from 'react';
import {LineChart, CartesianGrid, XAxis, Legend, Line, ResponsiveContainer} from 'recharts';
import * as moment from "moment";
import PropTypes from 'prop-types';
import {getMilestoneDays} from "./utils";

export default function GoalsProgressChart(props) {
    const daysInMilestone = getMilestoneDays(props.milestone);

    const plan = {
        name: 'Plan',
        data: [
            {day: 0, value: 0},
            {day: daysInMilestone, value: 100}
        ],
    };

    const series = [];

    for (let goal of props.goals) {
        if (goal.trackingType === "YES_NO") {
            continue;
        }

        const s = {
            name: goal.name,
            data: [
                {
                    day: 0,
                    value: 0
                },
                ...goal.trackings.map(t => {
                    return {
                        day: Math.max(moment(moment(t.date)).diff(props.milestone.startDate, 'days'), 0),
                        value: 100 * (t.value - goal.startValue) / (goal.endValue - goal.startValue)
                    };
                })
            ]
        };

        series.push(s);
    }

    const getRandomColor = (str) => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        let colour = '#';
        for (let i = 0; i < 3; i++) {
            let value = (hash >> (i * 8)) & 0xFF;
            colour += ('00' + value.toString(16)).substr(-2);
        }
        return colour;
    };

    return (
        <ResponsiveContainer width="99%" height={250}>
            <LineChart height={250} style={{margin: 'auto'}}>
                <CartesianGrid strokeDasharray="3 3"/>
                <XAxis dataKey="day" type="number"/>
                <Legend/>
                <Line dataKey="value"
                      type='number'
                      data={plan.data}
                      name='Plan'
                      key='Plan'
                      stroke='red'
                      strokeDasharray="5 5"
                />
                {series.map(s => (
                    <Line dataKey="value"
                          data={s.data}
                          name={s.name}
                          key={s.name}
                          stroke={getRandomColor(s.name)}
                          dot={false}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
}

GoalsProgressChart.propTypes = {
    milestone: PropTypes.object.isRequired,
    goals: PropTypes.arrayOf(PropTypes.object).isRequired
};

GoalsProgressChart.defaultProps = {};