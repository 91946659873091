import * as React from 'react';
import {List, Header, Image} from "semantic-ui-react";
import reading from "../images/Reading.jpg";
import pieChart from "../images/PieChart.JPG";
import progress from "../images/Progress.JPG";
import running from "../images/Running.jpg";
import thinking from "../images/Thinking.jpg";

class About extends React.Component {
    render() {
        return (
            <>
                <Header as='h2'>Set personal goals and track the progress</Header>
                <Image src={progress} style={{maxWidth: '300px'}}/>
                <Image src={pieChart} style={{maxWidth: '300px'}}/>
                <Header as='h4'>Bill Gates says that he reads about 50 books a year</Header>
                <Image src={reading} style={{maxWidth: '300px'}}/>
                <Header as='h4'>Mark Zuckerberg challenged himself to run 365 miles a year</Header>
                <Image src={running} style={{maxWidth: '300px'}}/>
                <Header as='h4'>Why don't do something like this?</Header>
                <Image src={thinking} style={{maxWidth: '300px'}}/>
                <List ordered>
                    <List.Item>Create milestone</List.Item>
                    <List.Item>Create goals</List.Item>
                    <List.Item>Track progress</List.Item>
                    <List.Item>Complete</List.Item>
                    <List.Item>Repeat!</List.Item>
                </List>
                <Header as='h4'>Just some examples</Header>
                <List>
                    <List.Item>Read X books</List.Item>
                    <List.Item>Run X miles</List.Item>
                    <List.Item>Ride a bike for X miles</List.Item>
                    <List.Item>Add X lbs to your bench press</List.Item>
                    <List.Item>Add X to your savings</List.Item>
                    <List.Item>Lose X lbs of weight</List.Item>
                    <List.Item>Attract X new followers to your page</List.Item>
                    <List.Item>Complete X online courses</List.Item>
                    <List.Item>Write X articles</List.Item>
                </List>
                <Header as='h4'>Good luck</Header>
            </>
        );
    }
}

About.propTypes = {};

About.defaultProps = {};

export default About