import {toastr} from "react-redux-toastr";
import history from "../history";
import {beginAjaxCall, endAjaxCall} from "./ajaxStatusActions";
import firebase from "../firebaseApp";
import * as types from "./actionTypes";
import {getUID} from "../components/utils";

export function loadGoals(milestoneId) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        //Clear goals
        dispatch({type: types.LOAD_GOALS_SUCCESS, data: []});
        firebase.firestore()
            .collection('goals')
            .where("milestoneId", "==", milestoneId)
            .get()
            .then((querySnapshot) => {
                let goals = querySnapshot.docs.map(doc => doc.data())

                if (goals) {
                    if (goals.length === 0) {
                        toastr.info('You don\'t have any goals in the milestone. It\'s time to create one', {
                            id: 'no-goals'
                        })
                    } else {
                        toastr.remove('no-goals');
                    }

                    dispatch({type: types.LOAD_GOALS_SUCCESS, data: goals});
                }

            })
            .catch(error => toastr.error('Error loading goals'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function updateGoal(goal, onSuccess) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        firebase.firestore()
            .collection('goals')
            .doc(goal.goalId)
            .update(goal)
            .then(() => {
                if (onSuccess){
                    onSuccess();
                }

                history.push('/');
            })
            .catch(error => toastr.error('Error updating goal'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function createGoal(goal, onSuccess) {
    return function (dispatch) {
        dispatch(beginAjaxCall());
        let goalId = getUID();

        firebase.firestore()
            .collection('goals')
            .doc(goalId)
            .set({ ...goal, goalId })
            .then(() => {
                if (onSuccess) {
                    onSuccess();
                }

                history.push('/' + goal.milestoneId);
            })
            .catch(error => toastr.error('Error adding goal'))
            .finally(() => dispatch(endAjaxCall()));
    }
}

export function deleteGoal(goal) {
    return function (dispatch, getState) {
        dispatch(beginAjaxCall());
        firebase.firestore()
            .collection('goals')
            .doc(goal.goalId)
            .delete()
            .then(() => {
                dispatch(loadGoals(goal.milestoneId));
            })
            .catch(error => toastr.error('Error deleting goal'))
            .finally(() => dispatch(endAjaxCall()));
    }
}
