export const trackingTypeOptions = [
    {
        text: 'Integer',
        value: 'INTEGER'
    },
    {
        text: 'Percents',
        value: 'PERCENTS'
    },
    {
        text: 'Yes/No',
        value: 'YES_NO'
    }
];

export const priorityOptions = [
    {
        text: 'Very Low',
        value: 1
    },
    {
        text: 'Low',
        value: 2
    },
    {
        text: 'Medium',
        value: 3
    },
    {
        text: 'High',
        value: 4
    },
    {
        text: 'Very High',
        value: 5
    }
];

export const colorOptions = [
    {
        text: 'Red',
        value: 'red'
    },
    {
        text: 'Green',
        value: 'green'
    },
    {
        text: 'Blue',
        value: 'blue'
    },
    {
        text: 'Yellow',
        value: 'yellow'
    },
    {
        text: 'Black',
        value: 'black'
    },
    {
        text: 'Orange',
        value: 'orange'
    }
];