import * as React from 'react';
import {Label, Icon, Button, Progress} from "semantic-ui-react";
import PropTypes from 'prop-types';

class GoalTrackerPercents extends React.Component {
    render() {
        if (this.props.completed) {
            if (this.props.value < 100) {
                return (
                    <Label color='red'>
                        Not Completed
                        <Icon name='delete'/>
                    </Label>
                );
            }

            if (this.props.value >= 100) {
                return (
                    <Label color='green'>
                        Completed
                        <Icon name='check'/>
                    </Label>
                );
            }
        }

        const buttonStyle = {
            width: '35px',
            minWidth: '35px',
            height: '35px',
            padding: '5px'
        };

        return (
            <>
                <Button.Group>
                    <Button basic
                            positive
                            onClick={() => this.props.trackGoal(this.props.value + 10)}
                            style={buttonStyle}
                    >
                        +10
                    </Button>
                    <Button
                        basic
                        positive
                        onClick={() => this.props.trackGoal(this.props.value + 5)}
                        style={buttonStyle}
                    >
                        +5
                    </Button>
                    <Button
                        basic
                        positive
                        onClick={() => this.props.trackGoal(this.props.value + 1)}
                        style={buttonStyle}
                    >
                        +1
                    </Button>
                    <Button
                        basic
                        negative
                        onClick={() => this.props.trackGoal(this.props.value - 1)}
                        style={buttonStyle}
                    >
                        -1
                    </Button>
                </Button.Group>
                <div style={{width: this.props.small ? '100px' : '150px', height: '57px'}}>
                    <Progress
                        percent={this.props.value}
                        style={{ margin: '10px 5px' }}
                        success={ this.props.goal.status >= 1 }
                        warning={ this.props.goal.status === 0 }
                        error={ this.props.goal.status === -1 }
                    >
                        {this.props.value}%
                    </Progress>
                </div>
            </>
        );
    }
}

GoalTrackerPercents.propTypes = {
    goal: PropTypes.object.isRequired,
    trackGoal: PropTypes.func.isRequired,
    completed: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
    small: PropTypes
};

GoalTrackerPercents.defaultProps = {};

export default GoalTrackerPercents;