import * as React from 'react';
import {Label, Icon, Button} from "semantic-ui-react";
import PropTypes from 'prop-types';

class GoalTrackerYesNo extends React.Component {
    render() {
        let result = this.props.goal.value !== 1 ?
            (
                <Label>
                    Not Completed
                </Label>
            ) :
            (
                <Label color='green'>
                    Completed
                    <Icon name='check'/>
                </Label>
            );

        return (
            <>
                <Button
                    basic
                    positive
                    icon
                    onClick={() => this.props.trackGoal(1)}
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-around',
                        width: '110px',
                        margin: '5px'
                    }}
                    disabled={this.props.goal.value === 1 || this.props.completed}
                >
                    <Icon name='check'/>
                    <div style={{margin: '0 5px'}}>Complete</div>
                </Button>
                <div style={{width: this.props.small ? '120px' : '170px', display: 'flex', justifyContent: 'center'}}>
                    {result}
                </div>
            </>
        );
    }
}

GoalTrackerYesNo.propTypes = {
    goal: PropTypes.object.isRequired,
    trackGoal: PropTypes.func.isRequired,
    completed: PropTypes.bool.isRequired,
    small: PropTypes
};

GoalTrackerYesNo.defaultProps = {};

export default GoalTrackerYesNo;