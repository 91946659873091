import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Input, Dropdown, Button, Popup, Icon, Header} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {createCategory} from "../actions/categoryActions";
import { colorOptions } from "./constants";
import PromptModal from "./PromptModal";

class NewCategoryPage extends React.Component {
    state = {
        dataChanged: false,
        name: undefined,
        color: 'green'
    };

    onNameChange = (event, {value}) => {
        this.setState({name: value, dataChanged: true});
    };

    onColorChange = (event, {value}) => {
        this.setState({color: value, dataChanged: true});
    };

    canCreate = () => {
        return this.state.name && this.state.color;
    };

    create = () => {
        this.props.createCategory({
            name: this.state.name,
            color: this.state.color
        }, () => this.setState({ dataChanged: false }))
    };

    render() {
        return (
            <>
                <PromptModal when={this.state.dataChanged} message='New Category was not created. Continue and lost changes?'/>
                <Header as='h4'>
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '10px'}}
                                as={Link}
                                to={`/categories`}
                            >
                                <Icon name='arrow left'/>
                            </Button>
                        }
                        content="Go back"
                        basic
                    />
                    New Category
                </Header>
                <div
                    style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px', maxWidth: '500px'}}>
                    <div style={{margin: '5px', width: '40px', minWidth: '40px'}}>Name:</div>
                    <Input
                        value={this.state.name}
                        style={{flexGrow: 1}}
                        placeholder='New Category'
                        onChange={this.onNameChange}
                    >
                        <input maxLength="100"/>
                    </Input>
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px'}}>
                    <div style={{margin: '5px', width: '40px', minWidth: '40px'}}>Color:</div>
                    <Dropdown
                        fluid
                        selection
                        value={this.state.color}
                        options={colorOptions}
                        style={{width: '150px'}}
                        onChange={this.onColorChange}
                    />
                </div>
                <Popup
                    trigger={
                        <Button
                            icon
                            style={{display: 'flex', alignItems: 'center', margin: '5px'}}
                            onClick={this.create}
                            disabled={!this.canCreate()}
                            positive>
                            <Icon name='save'/>
                            <div style={{margin: '0 5px'}}>Save</div>
                        </Button>
                    }
                    content="Create New Category"
                    basic
                />
            </>
        );
    }
}

NewCategoryPage.propTypes = {
    milestone: PropTypes.object.isRequired
};

NewCategoryPage.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    createCategory
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewCategoryPage);
