import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Dropdown, Button, Input, Icon, Popup, Header} from "semantic-ui-react";
import { colorOptions } from "./constants";
import {updateCategory} from "../actions/categoryActions";
import PromptModal from "./PromptModal";

class EditCategoryPage extends React.Component {
    constructor(props) {
        super(props);

        const category = props.categories.find(c => c.categoryId === props.match.params.categoryId);

        this.state = {
            dataChanged: false,
            name: category.name,
            color: category.color
        };
    }

    onNameChange = (event, {value}) => {
        this.setState({name: value, dataChanged: true});
    };

    onColorChange = (event, {value}) => {
        this.setState({color: value, dataChanged: true});
    };

    canSave = () => {
        return this.state.dataChanged && this.state.name && this.state.color;
    };

    save = () => {
        this.props.updateCategory({
            categoryId: this.props.match.params.categoryId,
            name: this.state.name,
            color: this.state.color
        }, () => this.setState({ dataChanged: false }));
    };

    render() {
        return (
            <>
                <PromptModal when={this.state.dataChanged} message='Category was not saved. Continue and lost changes?'/>
                <Header as='h4'>
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '10px'}}
                                as={Link}
                                to={`/categories`}
                            >
                                <Icon name='arrow left'/>
                            </Button>
                        }
                        content="Go back"
                        basic
                    />
                    Edit Category
                </Header>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px'}}>
                    <div style={{margin: '5px', width: '40px', minWidth: '40px'}}>Name:</div>
                    <Input
                        value={this.state.name}
                        style={{flexGrow: 1}}
                        onChange={this.onNameChange}
                    >
                        <input maxLength="100"/>
                    </Input>
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px'}}>
                    <div style={{margin: '5px', width: '40px', minWidth: '40px'}}>Color:</div>
                    <Dropdown
                        fluid
                        selection
                        value={this.state.color}
                        options={colorOptions}
                        style={{width: '150px'}}
                        onChange={this.onColorChange}
                    />
                </div>
                <Popup
                    trigger={
                        <Button
                            icon
                            onClick={this.save}
                            disabled={!this.canSave()}
                            positive
                            style={{display: 'flex', alignItems: 'center', margin: '5px'}}
                        >
                            <Icon name='save'/>
                            <div style={{margin: '0 5px'}}>Save</div>
                        </Button>
                    }
                    content="Save Category"
                    basic
                />
            </>
        );
    }
}

EditCategoryPage.propTypes = {

};

EditCategoryPage.defaultProps = {};

const mapStateToProps = state => ({
    categories: state.data.categories
});

const mapDispatchToProps = {
    updateCategory
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCategoryPage);