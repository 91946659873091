import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Input, Dropdown, Button, Icon, Popup, Header} from "semantic-ui-react";
import {updateGoal} from "../actions/goalActions";
import PropTypes from 'prop-types';
import PromptModal from "./PromptModal";
import {priorityOptions, trackingTypeOptions} from "./constants";

class EditGoalPage extends React.Component {
    constructor(props) {
        super(props);

        let goal;

        props.goals.forEach(g => {
            if (g.goalId === props.match.params.goalId) {
                goal = g;
            }
        });

        this.state = {
            dataChanged: false,
            goalId: goal.goalId,
            name: goal.name,
            trackingType: goal.trackingType,
            milestoneId: goal.milestoneId,
            categoryId: goal.categoryId,
            priority: goal.priority,
            startValue: goal.startValue,
            endValue: goal.endValue
        };
    }

    onNameChange = (event, {value}) => {
        this.setState({name: value, dataChanged: true});
    };

    onCategoryChange = (e, {value}) => {
        this.setState({categoryId: value, dataChanged: true});
    };

    onPriorityChange = (e, {value}) => {
        this.setState({priority: value, dataChanged: true});
    };

    onStartValueChange = (event, {value}) => {
        this.setState({startValue: value, dataChanged: true});
    };

    onEndValueChange = (event, {value}) => {
        this.setState({endValue: value, dataChanged: true});
    };

    canUpdate = () => {
        if (!this.state.dataChanged) {
            return false;
        }

        if (!this.state.name || this.state.name.length < 3 || !this.state.categoryId) {
            return false;
        }

        if (this.state.trackingType === "YES_NO" || this.state.trackingType === "PERCENTS") {
            return true;
        }

        return this.state.endValue;
    };

    update = () => {
        this.props.updateGoal({
            goalId: this.state.goalId,
            milestoneId: this.state.milestoneId,
            name: this.state.name,
            description: this.state.name,
            priority: this.state.priority,
            trackingType: this.state.trackingType,
            startValue: this.state.startValue,
            categoryId: this.state.categoryId,
            endValue: this.state.endValue
        }, () => this.setState({ dataChanged: false }));
    };

    render() {
        const valuerRequired = this.state.trackingType === "INTEGER" || this.state.trackingType === "NUMERIC";
        let categoryOptions;

        if (this.props.categories) {
            categoryOptions = this.props.categories.map(c => {
                return {
                    value: c.categoryId,
                    text: c.name
                }
            })
        }

        return (
            <>
                <PromptModal when={this.state.dataChanged} message='Goal was not saved. Continue and lost changes?'/>
                <Header as='h4'>
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '10px'}}
                                as={Link}
                                to={`/`}
                            >
                                <Icon name='arrow left'/>
                            </Button>
                        }
                        content="Go back"
                        basic
                    />
                    Edit Goal
                </Header>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px'}}>
                    <div style={{margin: '5px', width: '60px', minWidth: '60px'}}>Name:</div>
                    <Input
                        value={this.state.name}
                        style={{flexGrow: 1}}
                        placeholder='New Goal'
                        onChange={this.onNameChange}
                    >
                        <input maxLength="100"/>
                    </Input>
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px'}}>
                    <div style={{margin: '5px', width: '60px', minWidth: '60px'}}>Category:</div>
                    <Dropdown
                        fluid
                        selection
                        value={this.state.categoryId}
                        options={categoryOptions}
                        style={{width: '150px'}}
                        onChange={this.onCategoryChange}
                    />
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px'}}>
                    <div style={{margin: '5px', width: '60px', minWidth: '60px'}}>Priority:</div>
                    <Dropdown
                        fluid
                        selection
                        value={this.state.priority}
                        options={priorityOptions}
                        style={{width: '150px'}}
                        onChange={this.onPriorityChange}
                    />
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px'}}>
                    <div style={{margin: '5px', width: '60px', minWidth: '60px'}}>Type:</div>
                    <Dropdown
                        fluid
                        selection
                        value={this.state.trackingType}
                        options={trackingTypeOptions}
                        style={{width: '150px', minWidth: '150px'}}
                        disabled={true}
                    />
                </div>
                {valuerRequired ?
                    <div style={{display: 'flex', direction: 'row', alignItems: 'center', margin: '5px'}}>
                        <div style={{margin: '5px', width: '60px', minWidth: '60px'}}>Start:</div>
                        <Input
                            type='number'
                            value={this.state.startValue}
                            style={{width: '80px'}}
                            onChange={this.onStartValueChange}
                        />
                        <div style={{margin: '5px 5px 5px 10px', width: '30px', minWidth: '30px'}}>Goal:</div>
                        <Input
                            type='number'
                            value={this.state.endValue}
                            style={{width: '80px'}}
                            onChange={this.onEndValueChange}
                        />
                    </div> :
                    undefined
                }
                <Popup
                    trigger={
                        <Button
                            icon
                            onClick={this.update}
                            disabled={!this.canUpdate()}
                            positive
                            style={{display: 'flex', alignItems: 'center', margin: '5px'}}
                        >
                            <Icon name='save'/>
                            <div style={{margin: '0 5px'}}>Save</div>
                        </Button>
                    }
                    content="Save Goal"
                    basic
                />
            </>
        );
    }
}

EditGoalPage.propTypes = {
    milestone: PropTypes.object.isRequired
};

EditGoalPage.defaultProps = {};

const mapStateToProps = state => ({
    goals: state.data.goals,
    categories: state.data.categories
});

const mapDispatchToProps = {
    updateGoal
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditGoalPage);
