import firebase from 'firebase';

let firebaseConfig = {
    apiKey: "AIzaSyBNLUbK_P990fxB4_GlbtPnSVRMf9WIFIs",
    authDomain: "simplegoaltracker.firebaseapp.com",
    databaseURL: "https://simplegoaltracker.firebaseio.com",
    projectId: "simplegoaltracker",
    storageBucket: "",
    messagingSenderId: "272007602598",
    appId: "1:272007602598:web:0d837ee7983ee4af"
};

firebase.initializeApp(firebaseConfig);

export default firebase;