export default {
    auth: {
        isLogged: false
    },
    data: {
        categories: [],
        milestones: undefined,
        goals: undefined,
        includeCompleted: false
    },
    ajaxCallsInProgress: 0
};
