import * as React from 'react';
import {PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer} from 'recharts';
import PropTypes from 'prop-types';

export default function GoalsPieChart(props) {
    const COLORS = ['darkgreen', '#21ba45', '#f2c037', '#db2828'];
    const piChartData = [
        {name: 'Completed', value: props.goals.filter(g => g.status === 2).length},
        {name: 'On-track', value: props.goals.filter(g => g.status === 1).length},
        {name: 'Almost', value: props.goals.filter(g => g.status === 0).length},
        {name: 'Off-track', value: props.goals.filter(g => g.status === -1).length}
    ];

    const renderCustomizedLabel = ({
                                       cx, cy, midAngle, innerRadius, outerRadius, percent, index, value
                                   }) => {
        const RADIAN = Math.PI / 180;
        const radius = 25 + innerRadius + (outerRadius - innerRadius);
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        if (!value) {
            return undefined;
        }

        return (
            <text
                x={x}
                y={y}
                fill={COLORS[index]}
                textAnchor={x > cx ? "start" : "end"}
                dominantBaseline="central"
            >
                {piChartData[index].name} ({value})
            </text>
        );
    };

    return (
        <ResponsiveContainer width="99%" height={280}>
            <PieChart width={1500} height={280}>
                <Pie
                    data={piChartData}
                    cy={115}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                    isAnimationActive={false}
                >
                    {
                        piChartData.map((entry, index) => <Cell key={`cell-${index}`}
                                                                fill={COLORS[index % COLORS.length]}/>)
                    }
                    <Legend margin={{ top: 0, left: 0, right: 0, bottom: 0 }}/>
                    <Tooltip/>
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
}

GoalsPieChart.propTypes = {
    goals: PropTypes.arrayOf(PropTypes.object).isRequired
};

GoalsPieChart.defaultProps = {};