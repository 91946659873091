import * as React from 'react';
import {Modal, Button} from "semantic-ui-react";
import {Prompt, withRouter} from 'react-router-dom'
import PropTypes from 'prop-types';

class PromptModal extends React.Component {
    state = {
        showModal: false,
        lastLocation: null,
        confirmedNavigation: false
    };

    handlePrompt = (nextLocation) => {
        const {confirmedNavigation} = this.state;
        if (!confirmedNavigation) {
            this.showModal(nextLocation);
            return false;
        }

        return true;
    };

    showModal = (location) => this.setState({
        showModal: true,
        lastLocation: location,
    });

    closeModal = () => {
        this.setState({showModal: false});
    };

    handleOKClick = () => {
        this.closeModal();
        if (this.state.lastLocation) {
            this.setState({
                confirmedNavigation: true
            }, () => {
                // Navigate to the previously blocked location
                this.props.history.push(this.state.lastLocation.pathname)
            })
        }
    };

    render() {
        return (
            <>
                <Prompt when={this.props.when} message={this.handlePrompt}/>
                <Modal
                    open={this.state.showModal}
                    dimmer="inverted"
                    size='small'
                >
                    <Modal.Header>{this.props.message}</Modal.Header>
                    <Modal.Actions>
                        <Button onClick={this.handleOKClick}>
                            OK
                        </Button>
                        <Button onClick={this.closeModal}>
                            Cancel
                        </Button>
                    </Modal.Actions>
                </Modal>
            </>);
    }
}

PromptModal.propTypes = {
    when: PropTypes.bool.isRequired,
    save: PropTypes.func.isRequired,
    message: PropTypes.string.isRequired
};

PromptModal.defaultProps = {};

export default withRouter(PromptModal);