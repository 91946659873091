import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import configureStore from './store/configureStore';
import ReduxToastr from 'react-redux-toastr'

const store = configureStore();

ReactDOM.render(
    <Provider store={store}>
        <div>
            <App />
            <ReduxToastr
                transitionIn="fadeIn"
                transitionOut="fadeOut"/>
        </div>
    </Provider>,
    document.getElementById('root'));

if (module.hot) {
    module.hot.accept('./App', () => {
        const NewApp = require('./App').default;
        ReactDOM.render(
            <Provider store={store}>
                <div>
                    <NewApp />
                    <ReduxToastr
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"/>
                </div>
            </Provider>,
            document.getElementById('root')
        );
    });
}
