import * as React from 'react';
import {Link} from 'react-router-dom';
import {Divider, Button, Icon, Popup} from "semantic-ui-react";
import PropTypes from 'prop-types';

class Category extends React.Component {
    static getDerivedStateFromProps(props) {
        return {
            name: props.category.name,
            color: props.category.color,
            dataChanged: false
        }
    }

    update = () => {
        this.props.updateCategory({
            categoryId: this.props.category.categoryId,
            name: this.state.name,
            color: this.state.color
        });
    };

    delete = () => {
        this.props.deleteCategory(this.props.category);
    };

    render() {
        return (
            <div>
                <Divider style={{margin: 0}}/>
                <div style={{
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'center',
                    paddingTop: 0,
                    paddingBottom: 0,
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    margin: '5px'
                }}>
                    <div style={{margin: '5px', flexGrow: 1}}>{this.props.category.name}</div>
                    <div style={{
                        margin: '5px',
                        width: '100px',
                        color: this.props.category.color
                    }}>{this.props.category.color}</div>
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '0', border: 'none'}}
                                as={Link}
                                to={`categories/${this.props.category.categoryId}`}
                                positive
                            >
                                <Icon name='edit'/>
                            </Button>
                        }
                        content="Edit Category"
                        basic
                    />
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '0'}}
                                onClick={this.delete}
                                negative
                            >
                                <Icon name='delete'/>
                            </Button>
                        }
                        content="Delete Category"
                        basic
                    />
                </div>
            </div>
        );
    }
}

Category.propTypes = {
    category: PropTypes.object.isRequired
};

Category.defaultProps = {};

export default Category;