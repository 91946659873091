import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Header, Confirm, Popup, Button, Icon} from 'semantic-ui-react'
import Category from "./Category";
import {deleteCategory} from "../actions/categoryActions";

class CategoriesPage extends React.Component {
    state = {
        categoryToDelete: undefined,
        showConfirmation: false
    };

    tryDeleteCategory = (category) => {
        this.setState({showConfirmation: true, categoryToDelete: category});
    };

    cancelCategoryDeletion = () => {
        this.setState({showConfirmation: false});
    };

    deleteCategory = () => {
        this.props.deleteCategory(this.state.categoryToDelete);
        this.setState({showConfirmation: false});
    };

    render() {
        return (
            <>
                <Header as='h4'>
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '10px'}}
                                as={Link}
                                to={`/`}
                            >
                                <Icon name='arrow left'/>
                            </Button>
                        }
                        content="Go back"
                        basic
                    />
                    Categories
                </Header>
                <Popup
                    trigger={
                        <Button
                            icon
                            style={{display: 'flex', alignItems: 'center', margin: '5px', width: '140px', justifyContent: 'space-around'}}
                            as={ Link }
                            to='categories/newCategory'
                            positive
                        >
                            <Icon name='plus'/>
                            <div style={{margin: '0 5px'}}>New Category</div>
                        </Button>
                    }
                    content="New Category"
                    basic
                />
                {this.props.categories
                    .map(c =>
                        <Category
                            key={c.categoryId}
                            category={c}
                            deleteCategory={this.tryDeleteCategory}
                        />
                    )}
                <Confirm
                    open={this.state.showConfirmation}
                    header='Confirmation'
                    content='Delete Category?'
                    onCancel={this.cancelCategoryDeletion}
                    onConfirm={this.deleteCategory}
                />
            </>
        );
    }
}

CategoriesPage.propTypes = {};

CategoriesPage.defaultProps = {};

const mapStateToProps = state => ({
    categories: state.data.categories
});

const mapDispatchToProps = {
    deleteCategory
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CategoriesPage);