import * as React from 'react';
import {Label, Icon, Button, Progress} from "semantic-ui-react";
import PropTypes from 'prop-types';

class GoalTrackerInteger extends React.Component {
    render() {
        if (this.props.completed) {
            if (this.props.value < this.props.endValue) {
                return (
                    <Label color='red'>
                        Not Completed
                        <Icon name='delete'/>
                    </Label>
                );
            }

            if (this.props.value >= this.props.endValue) {
                return (
                    <Label color='green'>
                        Completed
                        <Icon name='check'/>
                    </Label>
                );
            }
        }

        const buttonStyle = {
            width: '35px',
            minWidth: '35px',
            height: '35px',
            padding: '5px'
        };

        let percents;
        let isBackward = false;

        if (this.props.goal.startValue < this.props.goal.endValue) {
            percents = 100 * (this.props.value - this.props.goal.startValue) / (this.props.goal.endValue - this.props.goal.startValue)
        } else {
            isBackward = true;
            percents = 100 * (this.props.goal.startValue - this.props.value) / (this.props.goal.startValue - this.props.goal.endValue)
        }

        return (
            <>
                <Button.Group>
                    {isBackward ?
                        <>
                            <Button basic positive onClick={() => this.props.trackGoal(this.props.value - 50)}
                                    style={buttonStyle}>-50</Button>
                            <Button basic positive onClick={() => this.props.trackGoal(this.props.value - 10)}
                                    style={buttonStyle}>-10</Button>
                            <Button basic positive onClick={() => this.props.trackGoal(this.props.value - 1)}
                                    style={buttonStyle}>-1</Button>
                            <Button basic negative onClick={() => this.props.trackGoal(this.props.value + 1)}
                                    style={buttonStyle}>+1</Button>
                        </>
                        :
                        <>
                            <Button basic positive onClick={() => this.props.trackGoal(this.props.value + 50)}
                                    style={buttonStyle}>+50</Button>
                            <Button basic positive onClick={() => this.props.trackGoal(this.props.value + 10)}
                                    style={buttonStyle}>+10</Button>
                            <Button basic positive onClick={() => this.props.trackGoal(this.props.value + 1)}
                                    style={buttonStyle}>+1</Button>
                            <Button basic negative onClick={() => this.props.trackGoal(this.props.value - 1)}
                                    style={buttonStyle}>-1</Button>
                        </>
                    }
                </Button.Group>
                <div style={{minWidth: this.props.small ? '90px' : '140px', height: '57px'}}>
                    <Progress
                        percent={percents}
                        style={{ margin: '10px 5px' }}
                        success={ this.props.goal.status >= 1 }
                        warning={ this.props.goal.status === 0 }
                        error={ this.props.goal.status === -1 }
                    >
                        {`${this.props.value} of ${this.props.goal.endValue}`}
                    </Progress>
                </div>
            </>
        );
    }
}

GoalTrackerInteger.propTypes = {
    goal: PropTypes.object.isRequired,
    trackGoal: PropTypes.func.isRequired,
    completed: PropTypes.bool.isRequired,
    value: PropTypes.number.isRequired,
    inputClassName: PropTypes.string.isRequired,
    small: PropTypes
};

GoalTrackerInteger.defaultProps = {};

export default GoalTrackerInteger;