import * as React from 'react';
import {Link} from 'react-router-dom';
import {Header, Popup, Button, Icon} from 'semantic-ui-react'
import About from "./About";

class AboutPage extends React.Component {
    render() {
        return (
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <Header as='h4' style={{alignSelf: 'flex-start'}}>
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '10px'}}
                                as={Link}
                                to={`/`}
                            >
                                <Icon name='arrow left'/>
                            </Button>
                        }
                        content="Go back"
                        basic
                    />
                    About
                </Header>
                <About/>
            </div>
        );
    }
}

AboutPage.propTypes = {};

AboutPage.defaultProps = {};

export default AboutPage