import * as React from 'react';
import {connect} from 'react-redux';
import {Menu} from 'semantic-ui-react'
import {loadMilestones} from "../actions/milestoneActions";
import NewMilestone from "./NewMilestone";
import Milestone from "./Milestone";

class MilestonesPage extends React.Component {
    selectMilestone = (milestoneId) => {
        this.props.history.push('/' + milestoneId);
    };

    componentDidMount() {
        this.props.loadMilestones(this.props.includeCompleted);
    }

    render() {
        if (!this.props.milestones) {
            return null;
        }

        let selectedMilestoneId = this.props.match.params.milestoneId;
        if (!selectedMilestoneId) {
            if (this.props.milestones.length > 0) {
                selectedMilestoneId = this.props.milestones[this.props.milestones.length - 1].milestoneId;
            } else {
                selectedMilestoneId = 'new-milestone';
            }

            this.props.history.push('/' + selectedMilestoneId);

            return null;
        }

        let isNewMilestone = selectedMilestoneId === 'new-milestone';
        let selectedMilestone;
        if (!isNewMilestone) {
            selectedMilestone = this.props.milestones.find(m => m.milestoneId === selectedMilestoneId);
            if (!selectedMilestone) {
                // Milestone not found
                this.props.history.push('/');
                return null;
            }
        }


        return (
            <div>
                <Menu tabular>
                    {this.props.milestones.map(m =>
                        <Menu.Item
                            name={m.name}
                            active={selectedMilestoneId === m.milestoneId}
                            onClick={() => this.selectMilestone(m.milestoneId)}
                        >
                            {m.name}
                        </Menu.Item>
                    )}
                    <Menu.Item
                        name='New Milestone'
                        active={selectedMilestoneId === 'new-milestone'}
                        onClick={() => this.selectMilestone('new-milestone')}
                    >
                        New Milestone
                    </Menu.Item>
                </Menu>
                {isNewMilestone ?
                    <NewMilestone/> :
                    <Milestone key={selectedMilestone.milestoneId} milestone={selectedMilestone}/>
                }
            </div>
        );
    }
}

MilestonesPage.propTypes = {};

MilestonesPage.defaultProps = {};

const mapStateToProps = state => ({
    milestones: state.data.milestones,
    includeCompleted: state.data.includeCompleted
});

const mapDispatchToProps = {
    loadMilestones
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MilestonesPage);