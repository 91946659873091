import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function groupsReducer(state = initialState.data, action) {
    switch (action.type) {
        case types.LOAD_MILESTONES_SUCCESS: {
            return {
                ...state,
                milestones: action.data,
                includeCompleted: action.includeCompleted
            }
        }
        case types.LOAD_CATEGORIES_SUCCESS: {
            return {
                ...state,
                categories: action.data
            }
        }
        case types.LOAD_GOALS_SUCCESS: {
            return {
                ...state,
                goals: action.data
            }
        }
        case types.TRACK: {
            return {
                ...state,
                goals: state.goals.map(g => g.goalId === action.tracking.goalId ? { ...g, trackings: [ ...(g.trackings || []), action.tracking ] } : g)
            }
        }
        case types.TRACK_ERROR: {
            return {
                ...state,
                goals: state.goals.map(g => g.goalId === action.tracking.goalId ? { ...g, tracking: g.tracking.filter(t => t !== action.tracking) } : g)
            }
        }
        default: {
            return state;
        }
    }
}
