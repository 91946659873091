import * as React from 'react';
import {connect} from 'react-redux';
import {Button, Input, Icon, Popup} from "semantic-ui-react";
import {DateInput} from "semantic-ui-calendar-react";
import {createMilestone} from "../actions/milestoneActions";
import * as moment from "moment";
import PromptModal from "./PromptModal";

class NewMilestone extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: 'New Milestone',
            startDate: moment().format('MM/DD/YYYY'),
            endDate: moment().endOf('year').format('MM/DD/YYYY'),
            dataChanged: false
        };
    }

    onNameChange = (event) => {
        this.setState({name: event.target.value, dataChanged: true});
    };

    onStartDateChange = (event, {value}) => {
        this.setState({startDate: value, dataChanged: true});
    };

    onEndDateChange = (event, {value}) => {
        this.setState({endDate: value, dataChanged: true});
    };

    canCreate = () => {
        return this.state.dataChanged &&
            this.state.name &&
            moment(this.state.startDate).isBefore(moment(this.state.endDate));
    };

    create = () => {
        this.props.createMilestone({
            name: this.state.name,
            startDate: moment(this.state.startDate).format('YYYY-MM-DD'),
            endDate: moment(this.state.endDate).format('YYYY-MM-DD')
        }, this.setState({
            name: 'New Milestone',
            startDate: moment().format('MM/DD/YYYY'),
            endDate: moment().endOf('year').format('MM/DD/YYYY'),
            dataChanged: false
        }));
    };

    render() {
        return (
            <>
                <PromptModal when={this.state.dataChanged}
                             message='Milestone was not saved. Continue and lost changes?'/>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center', marginBottom: '5px'}}>
                    <div style={{width: '50px', margin: '5px'}}>Name:</div>
                    <Input
                        value={this.state.name}
                        style={{flexGrow: 1}}
                        onChange={this.onNameChange}
                    >
                        <input maxLength="100"/>
                    </Input>
                </div>
                <div style={{display: 'flex', direction: 'row', alignItems: 'center'}}>
                    <div style={{width: '50px', margin: '5px'}}>Start:</div>
                    <DateInput
                        style={{width: '130px'}}
                        dateFormat='MM/DD/YYYY'
                        iconPosition='left'
                        value={this.state.startDate}
                        onChange={this.onStartDateChange}
                    />
                    <div style={{margin: '5px'}}>End:</div>
                    <DateInput
                        style={{width: '130px'}}
                        dateFormat='MM/DD/YYYY'
                        iconPosition='left'
                        value={this.state.endDate}
                        onChange={this.onEndDateChange}
                    />
                </div>
                <div>
                    <Popup
                        trigger={
                            <Button
                                icon
                                style={{display: 'flex', alignItems: 'center', margin: '5px'}}
                                onClick={this.create}
                                disabled={!this.canCreate()}
                                positive>
                                <Icon name='save'/>
                                <div style={{margin: '0 5px'}}>Create</div>
                            </Button>
                        }
                        content="Create New Milestone"
                        basic
                    />
                </div>
            </>
        );
    }
}

NewMilestone.propTypes = {};

NewMilestone.defaultProps = {};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
    createMilestone
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewMilestone);