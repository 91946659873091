import * as React from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Divider, Header, Button, Icon, Popup} from "semantic-ui-react";
import * as moment from "moment";
import GoalTrackerYesNo from "./GoalTrackerYesNo";
import GoalTrackerInteger from "./GoalTrackerInteger";
import GoalTrackerPercents from "./GoalTrackerPercents";
import {track} from "../actions/trackingActions";
import PropTypes from 'prop-types';
import {Mobile, Tablet} from "../responsive";

class Goal extends React.Component {
    getGoalTracker = (value, inputClassName, small) => {
        let content;
        switch (this.props.goal.trackingType) {
            case 'YES_NO':
                content = <GoalTrackerYesNo goal={this.props.goal}
                                            trackGoal={this.trackGoal}
                                            small={small}
                                            completed={this.props.milestone.completed}
                                            value={value}/>;
                break;
            case 'INTEGER':
                content = <GoalTrackerInteger goal={this.props.goal}
                                              trackGoal={this.trackGoal}
                                              small={small}
                                              completed={this.props.milestone.completed}
                                              inputClassName={inputClassName}
                                              value={value}/>;
                break;
            case 'PERCENTS':
                content = <GoalTrackerPercents goal={this.props.goal}
                                               trackGoal={this.trackGoal}
                                               small={small}
                                               completed={this.props.milestone.completed}
                                               inputClassName={inputClassName}
                                               value={value}/>;
                break;
            default:
                break;
        }

        return (
            <div style={{
                minWidth: small ? '240px' : '290px',
                width: small ? '240px' : '290px',
                display: 'flex',
                direction: 'row',
                alignItems: 'center'
            }}>
                {content}
            </div>)
    };

    getStatusIcon = (status) => {
        switch (status) {
            case 2:
                return <Icon name='check' color='green' circular inverted/>
            case 1:
                return <Icon name='thumbs up outline' color='green' circular inverted/>
            case 0:
                return <Icon name='warning' color='orange' circular inverted/>
            case -1:
                return <Icon name='thumbs down outline' color='red' circular inverted/>
            default:
                return undefined;
        }
    };

    trackGoal = (value) => {
        this.props.track({
            goalId: this.props.goal.goalId,
            date: moment().toISOString(),
            value
        }, this.props.goal);
    };

    canDelete = () => {
        return !this.props.milestone.completed;
    };

    delete = () => {
        this.props.deleteGoal(this.props.goal);
    };

    render() {
        let categoryColor;
        let categoryName;

        if (this.props.categories) {
            const category = this.props.categories.find(c => c.categoryId === this.props.goal.categoryId);

            if (category) {
                categoryColor = category.color || 'green';
                categoryName = category.name || 'unknown';
            }
        }

        let inputClassName;
        if (this.props.goal.status >= 1) {
            inputClassName = 'success';
        } else if (this.props.goal.status === 0) {
            inputClassName = 'warning';
        } else {
            inputClassName = 'error';
        }

        let priorityName;
        switch (this.props.goal.priority) {
            case 1:
                priorityName = 'Very Low';
                break;
            case 2:
                priorityName = 'Low';
                break;
            case 3:
                priorityName = 'Medium';
                break;
            case 4:
                priorityName = 'High';
                break;
            case 5:
                priorityName = 'Very High';
                break;
            default:
                priorityName = 'Unknown';
                break;
        }

        return (
            <div>
                <Divider style={{margin: 0}}/>
                <div style={{
                    display: 'flex',
                    direction: 'row',
                    alignItems: 'center',
                    paddingTop: 0,
                    paddingBottom: 0,
                    margin: '5px',
                    paddingLeft: '8px',
                    paddingRight: '8px',
                    minHeight: '57px'
                }}>
                    <div style={{margin: 'auto 0'}}>
                        {this.getStatusIcon(this.props.goal.status)}
                    </div>
                    <Tablet>
                        {this.getGoalNameLabel()}
                        {this.getCategoryLabel(categoryColor, categoryName)}
                        {this.getPriorityLabel(priorityName)}
                        {this.getGoalTracker(this.props.goal.value, inputClassName)}
                    </Tablet>
                    <Mobile>
                        <div style={{flexGrow: 1}}>
                            <div style={{
                                display: 'flex',
                                direction: 'row',
                                alignItems: 'center'
                            }}>
                                {this.getGoalNameLabel()}
                                {this.getCategoryLabel(categoryColor, categoryName)}
                                {this.getPriorityLabel(priorityName)}
                            </div>
                            <div style={{
                                display: 'flex',
                                direction: 'row',
                                alignItems: 'center'
                            }}>
                                <div style={{flexGrow: 1}}>
                                </div>
                                {this.getGoalTracker(this.props.goal.value, inputClassName, true)}
                            </div>
                        </div>
                    </Mobile>
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '0'}}
                                disabled={!this.canDelete()}
                                as={Link}
                                to={`goals/${this.props.goal.goalId}`}
                                positive
                            >
                                <Icon name='edit'/>
                            </Button>
                        }
                        content="Edit Goal"
                        basic
                    />
                    <Popup
                        trigger={
                            <Button
                                basic
                                circular
                                icon
                                style={{margin: '0'}}
                                onClick={this.delete}
                                disabled={!this.canDelete()}
                                negative
                            >
                                <Icon name='delete'/>
                            </Button>
                        }
                        content="Delete Goal"
                        basic
                    />
                </div>
            </div>
        );
    }

    getPriorityLabel = (priorityName) => {
        return <Header as='h5' style={{margin: '5px', width: '50px', minWidth: '50px', textOverflow: 'ellipsis'}}>
            <em>{priorityName}</em>
        </Header>;
    };

    getCategoryLabel = (categoryColor, categoryName) => {
        return <Header as='h5' style={{
            color: categoryColor,
            margin: '5px',
            width: '100px',
            minWidth: '100px',
            textOverflow: 'ellipsis'
        }}>
            <em>{categoryName}</em>
        </Header>;
    };

    getGoalNameLabel = () => {
        return <Header
            as='h5'
            style={{
                flexGrow: 1,
                minWidth: '80px',
                margin: '5px',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            }}>
            {this.props.goal.name}
        </Header>;
    };
}

Goal.propTypes = {
    goal: PropTypes.object.isRequired,
    milestone: PropTypes.object.isRequired,
};

Goal.defaultProps = {};

const mapStateToProps = state => ({
    categories: state.data.categories
});

const mapDispatchToProps = {
    track
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Goal);