import React from 'react';
import {connect} from 'react-redux';
import {Route, Switch, Link} from 'react-router-dom';
import {Menu, Image, Icon, Dropdown} from 'semantic-ui-react'
import MilestonesPage from "./MilestonesPage";
import PropTypes from "prop-types";
import {loadMilestones} from "../actions/milestoneActions";
import AboutPage from "./AboutPage";
import CategoriesPage from "./CategoriesPage";
import EditCategoryPage from "./EditCategoryPage";
import EditGoalPage from "./EditGoalPage";
import {loadCategories} from "../actions/categoryActions";
import NewGoalPage from "./NewGoalPage";
import NewCategoryPage from "./NewCategoryPage";
import {Tablet} from "../responsive";

class Main extends React.Component {
    componentDidMount() {
        this.props.loadCategories();
    }

    render() {
        return (
            <>

                <Menu attached='top'>
                    <Menu.Item as='a' header style={{flexGrow: 1}}>
                        <Image size='mini' src='/favicon.ico' style={{marginRight: '1.5em'}}/>
                        <Tablet>
                            Simple Goal Tracker
                        </Tablet>
                    </Menu.Item>

                    <Menu.Menu position='right'>
                        <Menu.Menu position="right">
                            <Dropdown
                                item
                                className={'bottom right'}
                                icon={
                                    <Icon.Group>
                                        <Icon
                                            circular
                                            inverted
                                            name="settings"
                                        />
                                    </Icon.Group>
                                }
                            >
                                <Dropdown.Menu className={'user-dropdown'}>
                                    {this.props.includeCompleted ?
                                        <Dropdown.Item onClick={() => {
                                            this.props.loadMilestones(false)
                                        }} text="Hide Completed Milestones"/> :
                                        <Dropdown.Item onClick={() => {
                                            this.props.loadMilestones(true)
                                        }} text="Show Completed Milestones"/>
                                    }
                                    <Dropdown.Item as={Link} name='categories' to='/categories' text="Categories"/>
                                    <Dropdown.Item as={Link} name='about' to='/about' text='About'/>
                                </Dropdown.Menu>
                            </Dropdown>
                        </Menu.Menu>
                        <Menu.Item as='a' header>
                            {this.props.pictureUrl ? <Image src={this.props.pictureUrl} avatar /> : <Icon name='user circle' size='big'/>}
                            <Tablet>{this.props.userName}</Tablet>
                        </Menu.Item>
                        <Menu.Item
                            name='logout'
                            onClick={this.props.onLogout}
                        />
                    </Menu.Menu>
                </Menu>
                <div>
                    <Switch>
                        <Route path="/about" component={AboutPage}/>
                        <Route path="/categories/newCategory" component={NewCategoryPage}/>
                        <Route path="/categories/:categoryId" component={EditCategoryPage}/>
                        <Route path="/categories" component={CategoriesPage}/>
                        <Route path="/goals/newGoal/:milestoneId" component={NewGoalPage}/>
                        <Route path="/goals/:goalId" component={EditGoalPage}/>
                        <Route path="/:milestoneId?" component={MilestonesPage}/>
                    </Switch>
                </div>
            </>
        );
    }
}

Main.propTypes = {
    onLogout: PropTypes.func.isRequired,
    userName: PropTypes.string.isRequired,
    pictureUrl: PropTypes.string,
    loading: PropTypes.bool.isRequired
};

const mapStateToProps = state => ({
    includeCompleted: state.data.includeCompleted
});

const mapDispatchToProps = {
    loadMilestones,
    loadCategories
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Main);

